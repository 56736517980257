import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import {
  IoIosClose,
  IoIosArrowDropleft,
  IoIosArrowDropright,
} from "react-icons/io"

const LightboxContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  z-index: 5;
  font-family: Montserrat, sans-serif;

  display: flex;
  justify-content: center;
  align-items: center;
`
const LightboxImage = styled(Img)`
  width: 80%;
  height: auto;
  max-height: 65%;
  z-index: 6;
  margin-top: 50px;

  @media (min-width: 768px) {
    img {
      width: auto !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
  }
`

const StyledIoIosClose = styled(IoIosClose)`
  position: absolute;
  right: 10px;
  top: 10px;
  height: 36px;
  width: 36px;
  color: white;
  cursor: pointer;

  @media (min-width: 768px) {
    height: 70px;
    width: 70px;
  }
`

const StyledIoIosArrowDropleft = styled(IoIosArrowDropleft)`
  position: absolute;
  top: 50%;
  left: 0%;
  height: 40px;
  width: 40px;
  color: white;
  z-index: 7;
  cursor: pointer;

  @media (min-width: 768px) {
    left: 2.55%;
    height: 52px;
    width: 52px;
  }
`

const StyledIoIosArrowDropright = styled(IoIosArrowDropright)`
  position: absolute;
  top: 50%;
  right: 0%;
  height: 40px;
  width: 40px;
  color: white;
  z-index: 7;
  cursor: pointer;

  @media (min-width: 768px) {
    height: 52px;
    width: 52px;
    right: 2.55%;
  }
`

const LightboxImageDescription = styled.div`
  color: white;
  font-size: 16px;
  position: absolute;
  top: 2%;
  left: 2%;
  max-width: 90%;

  font-size: 12px;

  h3 {
    margin: 0;
    font-size: 20px;
    font-family: Montserrat, sans-serif;
    font-weight: normal;
  }

  @media (min-width: 768px) {
    max-width: 85%;
    h3 {
      font-size: 26px;
      font-family: Montserrat, sans-serif;
      font-weight: normal;
    }
    p {
      font-size: 14px;
    }
  }
`
export default function Lightbox({
  images,
  currentImage,
  onClose,
  onPrevious,
  onNext,
}) {
  return (
    <LightboxContainer onContextMenu={e => e.preventDefault()}>
      <StyledIoIosClose onClick={onClose} />
      <StyledIoIosArrowDropleft onClick={onPrevious} />
      <LightboxImage
        fluid={images[currentImage].fluid}
        alt={images[currentImage].title}
      />
      <LightboxImageDescription>
        <h3>{images[currentImage].title}</h3>
        <p>{images[currentImage].description}</p>
      </LightboxImageDescription>
      <StyledIoIosArrowDropright onClick={onNext} />
    </LightboxContainer>
  )
}
