import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { ContentContainer } from "../components/Theme"
import { truncate } from "../utils/strings"
import Lightbox from "../components/Lightbox"

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
`

const ImageContainer = styled.div`
  margin: 10px 0;
  h3 {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    margin: 6px 0 0 0;
    font-size: 18px;
  }
  p {
    font-size: 14px;
    margin: 0;
  }
`

const StyledImg = styled(Img)`
  height: 350px;
  object-fit: cover;
`

export default function GalleryPageContentful({ data, location }) {
  const gallery = data.contentfulGallery
  const galleryImages = data.contentfulGallery.images
  const siteTitle = data.site.siteMetadata.title

  const [currentImage, setCurrentImage] = useState(0)
  const [lightboxToggled, setLightboxToggled] = useState(false)
  const [domLoaded, setDomLoaded] = useState(false)

  function previousImage(currentImage) {
    if (currentImage > 0) {
      setCurrentImage((currentImage -= 1))
    } else {
      setCurrentImage(galleryImages.length - 1)
    }
  }

  function nextImage(currentImage, galleryImages) {
    if (currentImage < galleryImages.length - 1) {
      setCurrentImage((currentImage += 1))
    } else {
      setCurrentImage(0)
    }
  }

  useEffect(() => {
    setDomLoaded(true)
    return
  }, [])

  if (domLoaded) {
    document.onkeydown = function (e, onPrevious, onNext) {
      if (lightboxToggled) {
        switch (e.keyCode) {
          case 37:
            previousImage(currentImage)
            break
          case 39:
            nextImage(currentImage, galleryImages)
            break
          case 27:
            setLightboxToggled(false)
            break
          default:
            break
        }
      }
    }
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={gallery.title} />
      {lightboxToggled && (
        <Lightbox
          images={galleryImages}
          currentImage={currentImage}
          onClose={() => setLightboxToggled(false)}
          onPrevious={() => previousImage(currentImage)}
          onNext={() => nextImage(currentImage, galleryImages)}
        />
      )}
      <ContentContainer>
        <h1>{gallery.title}</h1>
        <ImageGrid>
          {galleryImages.map((image, index) => (
            <ImageContainer
              onClick={() => {
                setCurrentImage(index)
                setLightboxToggled(true)
              }}
              key={index}
              onContextMenu={e => e.preventDefault()}
              data-sal="fade"
              data-sal-easing="ease"
            >
              <StyledImg
                key={image.fluid.base64}
                fluid={image.fluid}
                alt={image.title}
              />
              <h3>{image.title}</h3>
              <p>{truncate(image.description, 96)}</p>
            </ImageContainer>
          ))}
        </ImageGrid>
      </ContentContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContentfulGalleryPageByURL($url: String!) {
    site {
      siteMetadata {
        title
      }
    }

    contentfulGallery(url: { eq: $url }) {
      images {
        fluid(quality: 75, maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
        title
        description
      }
      title
    }
  }
`
